console.log("Vite ⚡️ Rails");
console.log("Visit the guide for more information: ", "https://vite-ruby.netlify.app/guide/rails");

//=========================================================================
// Rails, Turbo, and ActiveStorage
//=========================================================================
import * as Turbo from "@hotwired/turbo";
Turbo.start();

// import Rails from "@rails/ujs";
// Rails.start();

//=========================================================================
// StimulusJS
//=========================================================================
import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
const controllers = import.meta.globEager("@/controllers/*_controller.js");
registerControllers(application, controllers);

// Import and register all TailwindCSS Components
import { Dropdown, Modal, Tabs, Popover } from "tailwindcss-stimulus-components";
// application.register('dropdown', Dropdown)
application.register("modal", Modal);
application.register("tabs", Tabs);
// application.register('popover', Popover)

//=========================================================================
// Stylesheets
//=========================================================================
import "@/stylesheets/marketing.css";

import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/css/brands.css";

import "flatpickr/dist/flatpickr.css";

import "@/stylesheets/components/badges.css";
import "@/stylesheets/components/buttons.css";
import "@/stylesheets/components/links.css";
import "@/stylesheets/components/mobile_menu.css";

import "@/stylesheets/marketing/base.css";
import "@/stylesheets/marketing/hero.css";
import "@/stylesheets/marketing/faq.css";
